import * as React from 'react'
import { Link } from 'gatsby'
import MainLogo from '../images/full-logo.png'

const Header = () => {
  return (
    <header className="bg-grunge-pattern py-3 px-5">
      <div className="relative flex flex-wrap justify-around items-center 2xl:container">
        <div className="w-32 md:w-52 mb-4">
          <Link to="/">
            <img
              src={MainLogo}
              alt="Main Logo Northern Arizona Hunting And Fishing"
            />
          </Link>
        </div>
        <nav className="flex justify-center flex-wrap xs:mt-3">
          <Link
            to="/"
            className="text-gray py-1 sm:py-0 font-signika text-sm md:text-lg lg:text-xl mr-2 sm:mr-5"
          >
            Home
          </Link>
          <Link
            to="/hunting"
            className="text-gray py-1 sm:py-0 font-signika text-sm md:text-lg lg:text-xl mr-2 sm:mr-5"
          >
            Hunting
          </Link>
          <Link
            to="/fishing"
            className="text-gray py-1 sm:py-0 font-signika text-sm md:text-lg lg:text-xl mr-2 sm:mr-5"
          >
            Fishing
          </Link>
          <Link
            to="/azgfd"
            className="text-gray py-1 sm:py-0 font-signika text-sm md:text-lg lg:text-xl mr-2 sm:mr-5"
          >
            AZ Game &amp; Fish
          </Link>
          <Link
            to="/lifestyle"
            className="text-gray py-1 sm:py-0 font-signika text-sm md:text-lg lg:text-xl mr-2 sm:mr-5"
          >
            Lifestyle
          </Link>
          <Link
            to="/conservation"
            className="text-gray py-1 sm:py-0 font-signika text-sm md:text-lg lg:text-xl"
          >
            Conservation
          </Link>
        </nav>
      </div>
    </header>
  )
}

export default Header
