import * as React from 'react'
import { Link } from 'gatsby'
import MainLogo from '../images/full-logo.png'

const Footer = () => {
  return (
    <footer className="bg-grunge-pattern">
      <div className="max-w-7xl mx-auto py-16 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="mb-4 flex justify-center">
          <Link to="/">
            <img
              src={MainLogo}
              alt="Main Logo Northern Arizona Hunting And Fishing"
            />
          </Link>
        </div>
        <nav className="flex flex-wrap justify-center mb-4">
          <div className="px-1 sm:px-5 py-2">
            <Link
              to="/"
              className="text-sm sm:text-base text-gray hover:text-white"
            >
              Home
            </Link>
          </div>
          <div className="px-1 sm:px-5 py-2">
            <Link
              to="/hunting"
              className="text-sm sm:text-base text-gray hover:text-white"
            >
              Hunting
            </Link>
          </div>
          <div className="px-1 sm:px-5 py-2">
            <Link
              to="/fishing"
              className="text-sm sm:text-base text-gray hover:text-white"
            >
              Fishing
            </Link>
          </div>
          <div className="px-1 sm:px-5 py-2">
            <Link
              to="/lifestyle"
              className="text-sm sm:text-base text-gray hover:text-white"
            >
              Lifestyle
            </Link>
          </div>
          <div className="px-1 sm:px-5 py-2">
            <Link
              to="/azgfd"
              className="text-sm sm:text-base text-gray hover:text-white"
            >
              AZ Game &amp; Fish
            </Link>
          </div>
          <div className="px-1 sm:px-5 py-2">
            <Link
              to="/conservation"
              className="text-sm sm:text-base text-gray hover:text-white"
            >
              Conservation
            </Link>
          </div>
        </nav>
        <div className="flex justify-center">
          <p className="text-gray text-center">
            &copy; {new Date().getFullYear()} Williams GC Newspapers, Inc. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
