import * as React from 'react'

const TopBar = () => {
  return (
    <div className="bg-olive py-3 flex justify-center">
      <a href="/">
        <button className="py-1 px-4 text-sm sm:text-base bg-darkGreen text-white font-roboto rounded mr-2">
          Purchase Print Issue
        </button>
      </a>
      <a href="/">
        <button className="py-1 px-4 text-sm sm:text-base bg-orange text-white font-roboto rounded">
          Current Digital Issue
        </button>
      </a>
    </div>
  )
}

export default TopBar
